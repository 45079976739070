@import 'scss/variables/index.scss';

aside.pd-cms-sidebar {
  background: $pd-color-light-gray;
  padding: 0 $spacing-lg;
  overflow: auto;
  text-transform: uppercase;

  .ant-typography {
    padding: 24px;
  }

  .ant-menu {
    background: inherit;
    border-right: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .user-badge {
      cursor: pointer;
    }
  }

  .logo-container {
    width: 5rem;

    img {
      width: 100%;
    }
  }
}
