@import 'scss/variables/index.scss';

.rata-cms-blog-faq {
  .faq-item {
    padding: 16px 0;
    border-bottom: solid 1px $pd-color-gray;
    background: $pd-color-white;
  }
  .profile-image {
    min-width: 240px;
    img {
      display: block;
      width: 100%;
    }
  }
}
