@import 'scss/variables/index.scss';

.rata-cms-promotions {
  &-header {
    align-items: center;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  &-form-title {
    align-items: center;
    display: flex;

    .ant-row {
      width: 100%;
    }

    i {
      color: #c00d1e;
      font-size: 18px;
      margin-bottom: 0.5em;
      margin-left: 1em;
    }
  }

  &-floating-buttons {
    margin-top: 30px;

    .ant-row {
      width: 100%;
    }
  }

  .section-form {
    margin: 15px 0 30px;
  }
}

.ant-form {
  &:first-of-type {
    margin-bottom: -25px;
  }

  &:not(:first-of-type) {
    border-top: solid 1px #bdbdbd;
    margin-top: 50px;
    padding-top: 30px;
  }
}
