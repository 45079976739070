@import 'scss/variables/index.scss';

.pd-cms-home-our-offers {
  .our-offer-item {
    padding: 8px 0;
  }

  .profile-image {
    max-width: 230px;
    img {
      display: block;
      width: 100%;
    }
  }

  .address-description {
    p {
      margin: 0;
      font-size: 14px;
    }
  }

  .action-group {
    align-items: center;
    display: flex;

    .edit {
      margin: 0 17px;

      &:hover {
        cursor: pointer;
      }

      i {
        color: #1890ff;
        font-weight: bold;
        font-size: 20px;
      }
    }

    .delete {
      &:hover {
        cursor: pointer;
      }

      i {
        color: #c00d1e;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}
