@import 'scss/variables/index.scss';

.pd-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  width: 100%;

  .ant-spin {
    color: $primary-color;
  }
}
