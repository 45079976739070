.rata-cms-selected-message {
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px 16px;

  .ant-btn {
    width: 100%;
  }
}
