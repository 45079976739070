@import 'scss/variables/index.scss';

.pd-cms-buying-step {
  .step-item {
    padding: 16px 0;
    border-bottom: solid 1px $pd-color-gray;
    background: $pd-color-white;
  }
  .profile-image {
    min-width: 120px;
    max-width: 160px;
    img {
      display: block;
      width: 100%;
    }
  }
}
