@font-face {
  font-family: 'Brandon';
  src: url('./fonts/Brandon_thin.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('./fonts/Brandon_light.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('./fonts/Brandon_reg.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('./fonts/Brandon_med.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon';
  src: url('./fonts/Brandon_blk.otf');
  font-weight: bold;
  font-style: normal;
}
