.pd-cms-generator {
  .input-container {
    margin-bottom: 10px;
  }

  .url-result {
    background-color: #eee;
    border-radius: 10px;
    padding: 15px 25px;
    margin-top: 50px;
    min-height: 150px;
    position: relative;

    .copy-btn {
      display: inline-block;
      padding: 10px;
      position: absolute;
      bottom: 10px;
      right: 30px;

      &:hover {
        background-color: #e1e1e1;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .ant-btn {
    margin-top: 10px;
  }
}
