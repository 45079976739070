.ant-layout-content {
  min-height: initial !important;
  overflow: auto;
  height: 100vh;
}

.ant-tag {
  font-size: 14px !important;
  padding: 3px 15px !important;
}

aside.pd-cms-sidebar {
  padding: 0 20px !important;
}

.ant-layout-header {
  height: 150px !important;
}
