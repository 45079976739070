.rata-cms-review-and-promo-youtubes {
  .label {
    font-size: 14px;
    margin: 30px 0 12px;
  }

  .ant-table-wrapper {
    margin-top: 30px;
  }

  .action-icon {
    display: flex;

    .edit {
      color: #3d91f7;
      font-size: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    .delete {
      color: #b12828;
      font-size: 20px;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
