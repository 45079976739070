@import 'scss/variables/index.scss';

.pd-cms-home-testimonials {
  .testimony-item {
    padding: 16px 0;
    &:not(:last-of-type) {
      border-bottom: solid 2px $pd-color-gray;
    }
  }
  .profile-image {
    min-width: 240px;
    img {
      display: block;
      width: 100%;
    }
  }
}
