@import 'scss/variables/index.scss';

.pd-cms-home-doctors {
  .doctor-item {
    padding: 16px 0;
    &:not(:last-of-type) {
      border-bottom: solid 2px $pd-color-gray;
    }
  }
  .profile-image {
    max-width: 230px;

    img {
      display: block;
      width: 100%;
    }
  }
}
