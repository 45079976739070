.rata-cms-promo-floating-banners {
  .label {
    font-size: 14px;
    margin: 30px 0 12px;
  }

  .ant-table-wrapper {
    margin-top: 30px;
  }

  .action-icon {
    display: flex;

    .edit {
      color: #3d91f7;
      font-size: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
