@import 'scss/utils/index';
@import 'scss/variables/index';
@import 'scss/antd/index';

body {
  font-family: 'Roboto', cursive;
  font-size: 16px;
  max-width: 1280px;
  background: #fafafa;
  @media screen and (min-width: 1280px) {
    margin: 0 auto;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

ul {
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  color: inherit;

  &:hover {
    color: $primary-color;
  }
}

.pd-cms {
  height: 100vh;

  header {
    background: $pd-color-white;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;

    .ant-typography {
      margin-top: $spacing-lg;
    }
  }

  main {
    background: $pd-color-white;
    padding: 0 50px 50px;
  }

  .scale-img {
    height: 8vw !important;
    width: auto !important;
  }
}

.rc-color-picker-trigger {
  width: 7em;
  height: 6em;
  margin-top: 0.7em;
}

.btn-font {
  font-size: 18px;
}

.btn-layout {
  height: 75;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
}

.draggable-item:hover {
  cursor: move;
  background: #fafafa;
}

.ql-container.ql-snow {
  height: 70vh;
}

.ant-typography {
  color: rgba(0, 0, 0, 0.65) !important;
}
