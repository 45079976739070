.promotions-list {
  .ant-switch-checked {
    background-color: #7db45d;
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    &:not(:last-of-type) {
      border-bottom: solid 1px #cccccc;
    }

    .action-group {
      align-items: center;
      display: flex;

      .switch {
        display: flex;
      }

      .duplicate {
        margin-left: 25px;

        &:hover {
          cursor: pointer;
        }

        i {
          font-weight: bold;
          font-size: 20px;
        }
      }

      .edit {
        display: flex;
        margin: 0 17px;

        &:hover {
          cursor: pointer;
        }

        i {
          color: #1890ff;
          font-weight: bold;
          font-size: 20px;
        }
      }

      .delete {
        display: flex;

        i {
          color: #c00d1e;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }
  }
}
