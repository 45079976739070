@import 'scss/mixins/index.scss';
@import 'scss/variables/index.scss';

.pd-cms {
  &-form-1 {
    .title {
      font-size: $pd-font-size-md;
      font-weight: 600;
    }

    .description {
      font-size: $pd-font-size-sm;
    }

    .btn {
      &-half {
        width: 50%;
      }

      &-full {
        width: 100%;
      }
    }

    textarea.ant-input {
      height: 150px;
      resize: none;
    }

    .box-shadow {
      border-radius: 5px;
      box-shadow: 0 0 12px 0 rgba(66, 75, 90, 0.16);
      font-size: $pd-font-size-md;
      line-height: 31px;
      margin-bottom: 40px;
      padding: 24px;
    }

    .cms-list {
      padding: 2rem 0;

      &:not(:last-of-type) {
        border-bottom: solid 1px $pd-color-gray;
      }

      .overlay {
        position: relative;

        .text-overlay {
          background-color: rgba(66, 75, 90, 0.58);
          bottom: 0;
          color: $pd-color-white;
          font-size: $pd-font-size-sm;
          position: absolute;
          width: 100%;
        }
      }

      .container {
        &--image {
          img {
            width: 100%;
          }
        }

        &--center {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &-row {
            align-items: center;
            flex-direction: row;
          }

          button {
            width: 100%;

            &:first-of-type {
              margin-right: 15px;
            }
          }
        }

        &--no-height {
          height: unset;
        }
      }
    }
    .ant-upload {
      min-height: 250px !important;
    }

    .cms-card {
      border: solid 1px $pd-color-gray;
      border-radius: 5px;
      margin: 3rem auto;
      padding: 2rem;

      &--gray {
        background-color: $pd-color-light-gray;
      }

      .description {
        margin-top: 0.5rem;
      }

      button {
        margin-top: 1rem;
      }
    }
  }
}

.ql-container.ql-snow {
  height: 300px !important;
}
