@import 'scss/mixins/index.scss';

.pd-login {
  @include abs-center;

  height: 90vh;

  .login-form {
    width: 100%;
    max-width: 300px;

    &-button {
      width: 100%;
    }
  }
}
